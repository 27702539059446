'use client'

import { Cell } from '@vinted/web-ui'
import classnames from 'classnames'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'
import { HorizontalScrollArea } from '@marketplace-web/shared/ui-helpers'
import {
  clickClosetPromotionEvent,
  clickEvent,
  clickListItemEvent,
  favouriteItemEvent,
} from '_libs/common/event-tracker/events'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ClosetPromotionItemDto } from 'types/dtos/closet-promotion-item'
import { ClosetUserModel } from 'types/models/closet-user'

import ClosetUserInfo from './ClosetUserInfo'
import ClosetItem from './ClosetItem'
import ClosetCTA from './ClosetCTA'
import { getPromotedClosetContentSource } from '../../utils/contentSource'
import { ClosetPromotionEngagementEventTarget } from '../../constants/closet-promotion'

type Props = {
  user: ClosetUserModel
  items: Array<ClosetPromotionItemDto>
  wide?: boolean
  position: number
  contentSource?: ContentSource
  homepageSessionId?: string
}

const MIN_PHOTO_COLLAGE_COUNT = 3

const Closet = ({
  user,
  items,
  wide = false,
  position,
  contentSource,
  homepageSessionId,
}: Props) => {
  const { track } = useTracking()
  const { user: currentUser } = useSession()

  const isViewingSelf = currentUser?.id === user.id

  const isCollageEnabled = !!items[0]?.photos && items[0].photos.length >= MIN_PHOTO_COLLAGE_COUNT

  function trackEngagementEvent(target: string, shouldTrackEngagement = true) {
    if (shouldTrackEngagement) {
      track(
        clickClosetPromotionEvent({
          ownerId: user.id,
          contentSource: getPromotedClosetContentSource(contentSource),
          target,
        }),
      )
    }
  }

  const handleItemClick = (item: ClosetPromotionItemDto, index: number) => {
    track(
      clickListItemEvent({
        id: item.id,
        position: index + 1,
        contentType: ListItemContentType.Item,
        contentSource: ContentSource.PromotedClosets,
        homepageSessionId,
      }),
    )
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.Item)
  }

  function handleCtaClick() {
    const event = clickEvent({
      target: ClickableElement.SeeWholeClosetCta,
      targetDetails: `${user.id}`,
    })

    track(event)
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.AllItems)
  }

  function handleUserInfoClick() {
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.UserProfile)
  }

  function handleViewProfileClick() {
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.ViewProfile)
  }

  function handleItemFavouriteToggle({
    itemId,
    isFollowEvent,
  }: {
    itemId: number
    isFollowEvent: boolean
  }) {
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        homepageSessionId,
        contentSource: getPromotedClosetContentSource(contentSource),
        itemOwnerId: user.id,
      }),
    )
    track(clickEvent({ target: ClickableElement.Favourite }))

    trackEngagementEvent(ClosetPromotionEngagementEventTarget.ItemFavourite, isFollowEvent)
  }

  const closetClass = classnames('closet', 'closet--with-horizontal-scroll', {
    'closet--wide': wide,
  })
  const closetItemClass = classnames('closet__item', { 'closet__item--collage': isCollageEnabled })

  return (
    <div className="closet-container">
      <Cell styling={Cell.Styling.Tight}>
        <div className="closet-container__item-horizontal-scroll">
          <div className={closetClass}>
            <ClosetUserInfo
              user={user}
              position={position}
              onClick={handleUserInfoClick}
              onViewProfileClick={handleViewProfileClick}
              isBusinessUser={!!items[0]!.business_user}
            />

            <HorizontalScrollArea
              controlsScrollType={HorizontalScrollArea.ControlScrollType.Partial}
            >
              {items.map((item, index) => (
                <HorizontalScrollArea.Item
                  className={closetItemClass}
                  key={`closet-item-${item.id}`}
                >
                  <ClosetItem
                    item={item}
                    onClick={() => handleItemClick(item, index)}
                    onItemFavouriteToggle={handleItemFavouriteToggle}
                    showPhotoCollage={isCollageEnabled && index === 0}
                    index={index}
                    homepageSessionId={homepageSessionId}
                    isViewingSelf={isViewingSelf}
                  />
                </HorizontalScrollArea.Item>
              ))}

              <HorizontalScrollArea.Item className={closetItemClass}>
                <ClosetCTA
                  itemCount={user.itemCount}
                  userId={user.id}
                  onClick={handleCtaClick}
                  position={items.length + 1}
                />
              </HorizontalScrollArea.Item>
            </HorizontalScrollArea>
          </div>
        </div>
      </Cell>
    </div>
  )
}

export default Closet
